import { render, staticRenderFns } from "./QueryData.vue?vue&type=template&id=21d1313e&scoped=true"
import script from "./QueryData.vue?vue&type=script&lang=js"
export * from "./QueryData.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21d1313e",
  null
  
)

export default component.exports